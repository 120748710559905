import React from 'react';
import style from './BigBlogCard.module.scss';
import Image from 'next/image';
import { Button } from '@/components/ui/button';
import { Icons } from '@/components/icons';

interface IProps {
  image: string;
  title: string;
  date: string;
  time: string;
}

export const BigBlogCard = ({ item }: { item: IProps }) => {
  return (
    <article className={style.article}>
      <div className={style.imgWrapper}>
        <Image
          src={item.image}
          alt={item.title}
          layout="fill"
          objectFit="cover"
        />
      </div>
      <div className={style.overlay}>
        <div className={style.overlayInner}>
          <div className={style.contentWrapper}>
            <p className={style.heading}>
              <span>{item.date}</span>
              <span>
                <Icons.dotIcon />
              </span>
              <span>{item.time} min read</span>
            </p>
            <div
              style={{
                marginBottom: '20px',
                border: '1px solid #4F4F4F',
                opacity: '20%',
              }}
            ></div>
            <p className="mb-5 text-left text-2xl font-semibold text-black">
              {item.title}
            </p>
            <Button className="rounded-full bg-black px-5 py-2 text-lg text-white hover:opacity-75">
              Read More
            </Button>
          </div>{' '}
        </div>
      </div>
    </article>
  );
};
