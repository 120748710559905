'use client';
import React, { useEffect, useRef, useState } from 'react';
import { motion, useInView } from 'framer-motion';
import style from './OurBlogSection.module.scss';
import { Label } from '@/components/client/Label/Label';
import { SmallBlogCard } from './components/SmallBlogCard/SmallBlogCard';
import { BigBlogCard } from './components/BigBlogCard/BigBlogCard';
// import img from '@/public/images/mock/blogSmall.png';
// import img1 from '@/public/images/mock/Rectangle 31.png';
// import img2 from '@/public/images/mock/Rectangle 32.png';

// import imgBig from '@/public/images/mock/blogBig.png';
import { blogsAPI } from '@/app/api/blogs/blogsApi';
import moment from 'moment-timezone';
import Link from 'next/link';
import { env } from '@/env.mjs';

/* const data = [
  {
    image: img,
    title: 'The Ultimate Guide to Choos-ing Paint Colors',
    date: '06 Mar 2024',
    time: '12 min to read',
  },
  {
    image: img1,
    title: 'How to Fix Common your Plumbing Problems',
    date: '08 Mar 2024',
    time: '11 min to read',
  },
  {
    image: img2,
    title: 'Design Ideas & Inspiration for Your Dream Kitchen',
    date: '12 Mar 2024',
    time: '8 min to read',
  },
];

const dataBig = [
  {
    image: imgBig,
    title: 'Top 10 DIY Home Improvement Projects for Beginners',
    date: '04 Mar 2024',
    time: '9 min to read',
  },
];
 */

console.log('Environment', env.NEXT_PUBLIC_APP_ENV);

export const OurBlogSection = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true }); // анимация сработает только один раз

  const [data, setData] = useState([]);

  useEffect(() => {
    getBlogs();
  }, []);

  async function getBlogs() {
    const { data } = await blogsAPI.getBlogs({ status: 'published' });

    setData(
      data.data.map((blog) => ({
        image: blog?.mainImage?.url,
        title: blog.title,
        date: moment(blog.created_at).format('MMM DD, YYYY'),
        time: blog.time_to_read,
        slug: blog.slug,
      }))
    );
  }

  return (
    <div ref={ref}>
      <div className="mb-10 flex items-center justify-start">
        <Label label="Our Blog" />
      </div>
      <motion.div
        initial={{ opacity: 0 }} // Начальные параметры анимации
        animate={isInView ? { opacity: 1 } : {}} // Анимация при появлении
        transition={{ duration: 0.5 }} // Длительность анимации
        className="mb-5 flex w-full flex-col items-start justify-between md:flex-row"
      >
        <h2 className="mb-4 w-full text-4xl font-bold">
          <p className="text-start">
            <span className="text-textBlack font-semibold">
              Your Guide to the Latest{' '}
            </span>
          </p>
          <p className="text-start font-semibold">
            <span className="text-textOrange">Trends, Tips</span>
            <span className="text-textBlack"> and Insights </span>
          </p>
        </h2>

        <p className="textGrey mb-4 w-full text-start font-normal md:mb-0 md:w-2/4">
          Discover the newest trends, expert tips, and valuable insights in home
          maintenance, remodeling, and construction on Tytum&apos;s blog.
        </p>
      </motion.div>
      <div style={{ borderBottom: '1px solid #4f4f4f', opacity: '30%' }}></div>
      <div className={style.articles}>
        <motion.div
          className={style.articlesBig}
          initial={{ opacity: 0 }} // Начальные параметры анимации для больших карточек
          animate={isInView ? { opacity: 1 } : {}} // Анимация при появлении
          transition={{ duration: 0.5 }} // Длительность анимации
        >
          {data?.slice(0, 1).map((item, idx) => (
            <Link href={`/blog/${item.slug}`} key={idx}>
              <BigBlogCard item={item} />
            </Link>
          ))}
        </motion.div>

        <div className={style.articlesSmall}>
          {data.slice(1, 4).map((item, idx) => (
            <motion.div
              key={idx}
              initial={{ opacity: 0, y: 20 }} // Начальные параметры анимации для маленьких карточек
              animate={isInView ? { opacity: 1, y: 0 } : {}} // Анимация при появлении
              transition={{ duration: 0.5 }} // Длительность анимации
              style={{ width: '100%' }}
            >
              <Link href={`/blog/${item.slug}`}>
                <SmallBlogCard item={item} />
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
      {/* <div className="flex justify-center pt-10">
        <a
          href="#"
          className="mb-16 flex items-start w-fit gap-2 bg-white text-black py-2 px-3 rounded-full hover:bg-gray-200 text-base text-start border border-black"
        >
          <span className="text-base">View All Blogs</span>
          <span>{<Icons.info />}</span>
        </a>
      </div> */}
    </div>
  );
};
