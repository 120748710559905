import axiosInstance from '@/lib/axiosInstance';
import { IBlog } from '@/types/blog';

interface IParams {
  filter?: Record<string, any>;
  sort?: string;
  limit?: number;
  skip?: number;
}

interface IMeta {
  total: number;
  limit: number;
  page: number;
  totalPages: number;
}

const getBlogs = (params: IParams) => {
  return axiosInstance.get<{ data: IBlog[]; meta: IMeta }>('/api/blogs', {
    params,
  });
};

export const blogsAPI = {
  getBlogs,
};
