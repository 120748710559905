import React from 'react';
import style from './SmallBlogCard.module.scss';
import Image from 'next/image';
import { Icons } from '@/components/icons';

interface IProps {
  image: string;
  title: string;
  date: string;
  time: string;
}

export const SmallBlogCard = ({ item }: { item: IProps }) => {
  return (
    <article className={style.article}>
      <div className={style.imgWrapper}>
        <Image
          src={item.image}
          alt={item.title}
          layout="fill"
          objectFit="cover"
          // width={0}
          // height={0}
          // sizes="100vw"
          // style={{ width: '100%', height: 'auto' }}
        />
      </div>
      <div className={style.contentWrapper}>
        <p className={style.heading}>
          <span>{item.date}</span>
          <span>
            <Icons.dotIcon />
          </span>
          <span>{item.time} min read</span>
        </p>
        <div
          style={{
            marginBottom: '10px',
            border: '1px solid #4F4F4F',
            opacity: '20%',
          }}
        ></div>
        <p className="mb-5 text-left text-xl font-semibold text-black">
          {item.title}
        </p>
      </div>
    </article>
  );
};
